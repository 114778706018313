import React from "react";
import Helmet from "react-helmet";

import { Link } from 'gatsby';

import classNames from "classnames";

import withStyles from "@material-ui/core/styles/withStyles";

import Header from "components/Header/Header.jsx";
import Footer from "components/Footer/Footer.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import HeaderLinks from "components/Header/HeaderLinks.jsx";
import Parallax from "components/Parallax/Parallax.jsx";

import comingSoonStyle from "assets/jss/material-kit-react/views/comingSoonStyle.jsx";

import WriteHere from "../HomePage/SubSections/WriteHere.jsx";
import EditHere from "../HomePage/SubSections/EditHere.jsx";
import FormatHere from "../HomePage/SubSections/FormatHere.jsx";
import BoxsetsHere from "../HomePage/SubSections/BoxsetsHere.jsx";
import ExportHere from "../HomePage/SubSections/ExportHere.jsx";



const dashboardRoutes = [];

class BetaProgramDetails extends React.Component {
  render() {
    const { classes, ...rest } = this.props;
    return (
      <div>
      <Helmet>
        <title>BookMerlin</title>
      </Helmet>
        <Header
          color="transparent"
          routes={dashboardRoutes}
          brand="BookMerlin"
          rightLinks={<HeaderLinks />}
          fixed
          changeColorOnScroll={{
            height: 50,
            color: "#009688"
          }}
          {...rest}/>
        {/*}<Parallax filter image={require("assets/img/background.jpg")} >
        <Parallax filter image={require("assets/img/backgroundShadedGray.png")}
        <Parallax filter image={require("assets/img/backgroundShaded.png")}*/}

        <Parallax filter image={require("assets/img/backgroundShaded.jpg")}
          style={{
            alignItems:'unset'
          }}
          id="invitation"
        >
          <div className={classes.container}>
            <div className={classes.section}>

            <GridContainer className={classes.gridContainer} justify='space-between' >


            <GridItem xs={12} sm={12} md={6} className={classes.gridItem}>
              {/*<div className={classes.float}>
                    <h2 className={classes.floatHeader+' '+classes.floatWrapper}>
                      <div  className={classes.headlineText}> </div>
                      <div className={classes.headlineText}>Ever ask yourself, why do I have to juggle so many copies of my manuscript?</div>
                      <div style={{
                        paddingLeft:'1em'
                      }}>
                        <div  className={classes.headlineBullet}>&bull; One for writing</div>
                        <div  className={classes.headlineBullet}>&bull; One for your editor</div>
                        <div  className={classes.headlineBullet}>&bull; One for formatting</div>
                      </div>

                    </h2>
                    <h4 className={classes.floatDescription}>

                    </h4>
              </div>*/}

              <div className={classes.sidelineMainLeft}>
                  <div classNamex={classes.headlineMain} style={{
                    fontSize:'2.0em',
                    borderx:'1px solid yellow',
                    lineHeight:'1.2',
                    fontWeight:'500',
                    textAlign:'left'
                  }}>BETA PROGRAM PARTICIPANTS</div>

                  <div className={classes.headlineMainContent} style={{

                  }}></div>
                  <br/>
                  <div className={classes.headlineMainContent} style={{

                  }}>Scroll down for a statement regarding our beta program</div>
                  {/*<div classNamex={classes.headlineMain} style={{
                    fontSize:'2.0em',
                    borderx:'1px solid yellow',
                    lineHeight:'1.2',
                    fontWeight:'500'
                  }}>WRITE&bull;EDIT&bull;FORMAT</div>*/}





                    {/*}<div classNamex={classes.headlineMain} style={{
                        fontSize:'2.0em',
                        borderx:'1px solid yellow',
                        lineHeight:'1.2',
                        fontWeight:'500'
                      }}>JUST ONE</div>*/}

                      {/*<div classNamex={classes.headlineMain} style={{
                          fontSize:'2.0em',
                          borderx:'1px solid yellow',
                          lineHeight:'1.2',
                          fontWeight:'500',
                          marginTop:'2em'
                        }}>WRITE &bull; EDIT &bull; FORMAT</div>

                        <div classNamex={classes.headlineMain} style={{
                            fontSize:'2.0em',
                            borderx:'1px solid yellow',
                            lineHeight:'1.2',
                            fontWeight:'500'
                          }}>HERE</div>*/}
                </div>




            </GridItem>
              <GridItem xs={12} sm={12} md={6}  className={classes.gridItem} >

              <div className={classes.sidelineMain} >
                <span className={classes.sidelineHeader}>BookMerlin Beta</span>
                <span className={classes.sidelineContent}>Launching soon</span>
                <span className={classes.sidelineContent}>Limited slots</span>
                {/*}<iframe class="mj-w-res-iframe" title='getInvitationIFrame' style={{
                  marginTopx:'.5em'
                }}frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0" src="https://app.mailjet.com/widget/iframe/53lH/pP5" width="70%"></iframe>
                */}
                <iframe style={{
                  marginTop:'1em'
                }} class="mj-w-res-iframe" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src="https://app.mailjet.com/widget/iframe/53lH/pR3" width="70%"></iframe>





              </div>


              </GridItem>
              {/*}<GridItem xs={12} sm={12} md={12}>

                <div className={classes.headlineMain}>Write, Edit and Format. Here.</div>
                <div className={classes.headlineSubMain}>And create boxsets, and share styles...</div>

              </GridItem>*/}
              </GridContainer>
            </div>
            </div>
          </Parallax>
          <div className={classNames(classes.main, classes.mainRaised)}>
          <GridContainer justify="center">
            {/*}<GridItem xs={12} sm={12} md={8}>
              <h2 className={classes.title}>BookMerlin Beta Launch!</h2>
              <h5 className={classes.description}>
                Launching soon!
              </h5>
            </GridItem>*/}

            <GridItem xs={12} sm={12} md={8}>
              <h2 className={classes.title}>Welcome!</h2>
              <p className={classes.greetingText}>
                We are very excited to have you aboard as a beta participant.
              </p>
              <p className={classes.greetingText}>
              You are on the ground floor and we are lucky to have you with us. We are looking forward to your input as we prepare to launch our beta product. We also hope to foster a long-lasting relationship with you so that we benefit from your valuable input with all future releases of BookMerlin. To show our gratitude, you will be provided with subscription discounts when we launch. This is one of the reasons for the limited number of beta slots available.

              </p>
              <p className={classes.greetingText}>
              To make communication as easy as possible, we have created the BookMerlin Users Group on Facebook. This group is brand new and we are just now adding members. Initially, membership will be limited to Beta Program participants only. At the bottom of this statement, please click the <b>JOIN US ON FACEBOOK</b> button to reach our Facebook group. Once there, request membership and we'll get you added. This is where the majority of beta program related communication will take place. We are really looking forward to meeting you on Facebook!

              </p>
              <p className={classes.greetingText}>
              So when are we launching beta? We are currently targeting mid-April. Hopefully sooner and possibly ( low possibility ) a week or two later. The product is essentially complete and is undergoing extensive testing and final polishing.

              </p>
              <p className={classes.greetingText}>
              Why did we create BookMerlin? Quite frankly, we saw the enormous potential for a creative writing ecosystem that removes burden while enabling authors to do what they do best, create.

              </p>
              <p className={classes.greetingText}>
              The main pillars of BookMerlin are writing, editing, formatting and collaborating. Once the foundation was laid down, our ideas for where to take it next exploded. That's where you come in.

              </p>
              <p className={classes.greetingText}>
              A couple of our nice-to-have ideas have already made it into beta. But we really want to hear from you. Now we will get our chance to do just that.

              </p>
              <p className={classes.greetingText}>
              There is much more to discuss but we'll leave that for our discussions in the user's group and via the occasional email from us to you.

              </p>
              <p className={classes.greetingText}>
              Once again, thank you very much for joining the BookMerlin Beta Program and we will see in you in the user's group.

              </p>
              <p className={classes.greetingText}>
                <b>Jeff - Founder</b>
              </p>


              <div class="mj-form" style={{
                marginBottom:'2em',
                width:'100%',
                display:'flex',
                justifyContent:'center',
                alignItems:'center'
              }}>
                <div class="w-preview-field-table"
                style={{
                  'box-sizing': 'border-box',
                  display: 'block',
                  width: '20em'}}>
                    <div class="w-preview-fields-content-row"
                      style={{'box-sizing': 'border-box', display: 'flex', 'flex-direction': 'column'}}>
                        <div class="w-preview-fields-content-cell" >

                        <div class="mj-form-button"
                          style={{
                          'font-size':'21px',
                          'font-family':'Helvetica',
                          'font-weight':'400',
                          'text-transform':'uppercase',
                          'text-align':'center',
                          'background-color': '#f39c12',
                          color: 'rgb(255, 255, 255)',
                          height: '2.5em',
                          'line-height': '2.5em',
                          'flex-grow': '1',
                          'margin': '0px',
                          'width': '100%',
                          'border-radius':'.5em',
                          }}>
                          <a target='_blank' href='https://www.facebook.com/groups/198225981472595/' className={classes.navLink}>
                          <div class="mj-subscribe-button" >
                                <div class="mj-subscribe-button-content" >
                                <b>Join us on Facebook</b>



                                </div>
                            </div>
                          </a>
                        </div>
				            </div>
                </div>
            </div>
            </div>


            </GridItem>
            <GridItem >
                <WriteHere />
                <EditHere />
                <FormatHere />
                <BoxsetsHere />
                <ExportHere />
            </GridItem>
          </GridContainer>
          </div>
        <Footer />
      </div>
    );
  }
}

export default withStyles(comingSoonStyle)(BetaProgramDetails);
